// eslint-disable-next-line require-jsdoc
export const useAuthRedirectPath = () => {
  return {
    // eslint-disable-next-line require-jsdoc
    setPath(path) {
      useCookie('BountyRedirectPath', { maxAge: 300000 }).value = path;
    },

    // eslint-disable-next-line require-jsdoc
    getPath() {
      return useCookie('BountyRedirectPath').value || '/catalog';
    },

    // eslint-disable-next-line require-jsdoc
    clearPath() {
      useCookie('BountyRedirectPath').value = null;
    },
  };
};
